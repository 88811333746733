import React, { useEffect } from "react";
import { connect } from "react-redux";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ReactBody from "react-body";

const ContactPage = ({ language }) => {
  const formID =
    language === "es" ? "contactese-con-nosotros" : "contact-us-102";

  useEffect(() => {
    if (language) {
      const scriptTag = document.getElementById("contact-scripts");
      const formTag = document.getElementById(`can-form-area-${formID}`);
      scriptTag.innerHTML = "";
      formTag.innerHTML = "";
      const script = document.createElement("script");
      script.src = `https://actionnetwork.org/widgets/v3/form/${formID}?format=js&source=widget`;
      script.async = true;
      document.getElementById("contact-scripts").appendChild(script);

      const style = document.createElement("link");
      style.type = "text/css";
      style.rel = "stylesheet";
      style.href =
        "https://actionnetwork.org/css/style-embed-whitelabel-v3.css";
      style.async = true;
      document.getElementById("contact-scripts").appendChild(style);
    }
  }, [language, formID]);

  return (
    <Layout id="contact">
      <SEO title="Contact" />
      <section className="content content-sub">
        <div className="content-inner">
          <div className="content-text">
            <h1>{language === "es" ? "Contactar" : "Contact"}</h1>
            <div className="contact-address">
              {/*<h4>2497 Jerome Ave, Bronx, NY 10468</h4>*/}
              <h4>(917) 727-4190</h4>
            </div>
            <div className="action-form" id={`can-form-area-${formID}`} />
            <div id="contact-scripts" />
          </div>
        </div>
      </section>
      <ReactBody className="contact" />
    </Layout>
  );
};

const mapStateToProps = ({ language }) => {
  return { language };
};

export default connect(mapStateToProps)(ContactPage);
